import Helmet from "react-helmet"
import React from "react"
import ExtendedHomepage from "src/components/extendedHomepage"
import HomepageLayout from "src/layouts/homepage"
import CtaDisclosurechff from "src/components/ctadisclosurechff"
import CtaDisclosure from "@tightrope/ctadisclosure"
import {isIeEdge,isChrome,isFirefox} from "@tightrope/lpscripts/browserdetect"


const homepageData = import("./homepage.json");

export default function HomePage() {
  let disclosure = null;
  if(isChrome()){
    disclosure = <CtaDisclosure data={homepageData}></CtaDisclosure>;
  }
  else{
    disclosure = <CtaDisclosurechff  data={homepageData}></CtaDisclosurechff>;
  }
  return(
    <HomepageLayout>
      <Helmet>
      <style type="text/css">{`
        #ctadisclosure-module--ctadisclaimer {
          max-width: 900px;
          margin: 0 auto;
        }

      `}
      </style>

      <title>Save any web page easily - Easy Save</title></Helmet>
      <ExtendedHomepage data={homepageData}>
        {disclosure}
      </ExtendedHomepage>
    </HomepageLayout>
  )
}
